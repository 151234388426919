<template>
<div>
  <!-- Broadcaster -->
  <div v-if="showControls">
    <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Channel:</div><b-input type="text" v-model="channel"/>
      <b-form-radio-group>
        <b-form-radio v-model="role" value="broadcaster">Broadcaster</b-form-radio>
        <b-form-radio v-model="role" value="receiver">Receiver</b-form-radio>
        <b-form-radio v-model="role" value="">Off</b-form-radio>
      </b-form-radio-group>
    </div>
    <video :srcObject.prop="stream" autoplay playsinline muted/>
  </div>
  <!-- Receiver -->
  <div v-else-if="role == 'receiver'">
    <div class="text-center" v-if="!receiverStream">
      <div>Waiting for connection...</div>
      <progress/>
    </div>
    <video v-else :srcObject.prop="receiverStream" class="w-100" autoplay playsinline muted/>
  </div>
  <!-- Broadcast -->
  <broadcast 
    v-if="role"
    :channel="channel"
    :stream="stream"
    @stream-change="onBroadcastUpdate"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("test-broadcast")
import broadcast from '@/components/agoraRTC.vue'
import { getUserMedia } from "@/services/mediautils"

export default {
  components: { 
    broadcast,
  },
  data() {
    return {
      showControls: true,
      role: null,
      users: null,
      channel: "test-broadcast",

      userStream: null,
      stream: null,
      receiverStream: null,
    };
  },
  watch: {
    async role(value) {
      log.log("role", value)
      this.stream = value == 'broadcaster' ? await this.getUserStream() : null;
    }
  },
  mounted() {
    if (this.$route.query.channel) {
      this.showControls = false;
      this.channel = this.$route.query.channel;
      this.role = "receiver";
    }
    else {
      this.$debug.isOn = true;
    }
    this.init();
  },
  methods: {
    async init() {
    },
    async getUserStream() {
      if (!this.userStream) {
        this.userStream = await getUserMedia({video:true, audio:true, definition: 'auto'});
      }
      return this.userStream;
    },
    onUsersChange(users) {
      this.users = users;
    },
    onBroadcastUpdate(stream) {
      this.receiverStream = stream;
    }
  }
}
</script>

<style>

</style>